// Theme Colors/Branding
$brand-primary: #0179d7;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #01b391;
$dark: #00345a;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #0179d7;
$white: #fff;
$accent: #018be5;
$accent-2: #1459BA;
$body-font: #222;
$error: #d81e00;
$body_bkg_color:#0179d7;
