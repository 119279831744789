.rpb-accordion {
  .accordion-item {
    border: 0;
    background: transparent !important;
    border-bottom: 2px solid #fff;
    &:last-of-type {
      border-bottom:0 !important;
    }

    .accordion-body {
      padding: 1.5rem 0px;
      color:#fff;
      p {
        &:last-of-type {
          margin-bottom:0;
        }
      }
      * {
        font-family:$paragraph-font;
      }
    }
    .accordion-button {
      background-color:transparent !important;
      color: #fff;
      font-weight: normal;
      font-size: 3em !important;
      line-height:1;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: .5em 0px;
      font-weight: bold;
      &:hover, &:active, &:focus {
        outline:none!important;
        box-shadow:none!important;
      }
      * {
        margin-bottom:0px;
      }
    }
    .accordion-button:not(.collapsed) {
      color: #fff;
    }
    .accordion-button::after {
      display:none!important;
      //background-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /></svg>") !important;
    }
    .accordion-button:not(.collapsed)::after {
      //background-image:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#ffffff' viewBox='0 0 16 16'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/></svg>") !important;
    }
  }
}
